// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table {
  margin: 0;
  width: 100%;
  height: auto;
  text-align: center;
  border-collapse: collapse;
}

.THead {
  margin: 0;
  width: 100%;
  height: auto;
}

.TBody {
  margin: 0;
  width: 100%;
  height: auto;
}

.Tr {
  margin: 0;
  width: 100%;
  height: auto;
}

.Th, .Td {
  margin: 0;
  width: auto;
  height: auto;
  padding: 10px;
  border: 1px solid var(--border-color);
}`, "",{"version":3,"sources":["webpack://./src/client/HoosatUI/src/Elements/Table/Table.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,SAAS;EACT,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAS;EACT,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAS;EACT,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAS;EACT,WAAW;EACX,YAAY;EACZ,aAAa;EACb,qCAAqC;AACvC","sourcesContent":["@import '../../theme.css';\n\n.Table {\n  margin: 0;\n  width: 100%;\n  height: auto;\n  text-align: center;\n  border-collapse: collapse;\n}\n\n.THead {\n  margin: 0;\n  width: 100%;\n  height: auto;\n}\n\n.TBody {\n  margin: 0;\n  width: 100%;\n  height: auto;\n}\n\n.Tr {\n  margin: 0;\n  width: 100%;\n  height: auto;\n}\n\n.Th, .Td {\n  margin: 0;\n  width: auto;\n  height: auto;\n  padding: 10px;\n  border: 1px solid var(--border-color);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
