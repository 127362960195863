import React, { useState } from 'react';
import { Flex, Icon } from '../../HoosatUI/';

import "./Menu.css";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Menu = () => {
  const [ t, i18n ] = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    window.scrollTo({
      top: 0,
    });
    setMenuOpen(!isMenuOpen);
  }

  return (
    <div className='menu-container'>
      <button className='menu-open-button' onClick={() => { setMenuOpen(!isMenuOpen) }} aria-label='Menu open button'>
          <Icon name={'menu'}></Icon>
      </button>
      
      <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
        <Link to={"/"} aria-label={t("menu.frontpage")} onClick={openMenu}>
          <svg
            width="210mm"
            height="297mm"
            viewBox="0 0 210 297"
            version="1.1"
            id="hoosat-menu-svg">
            <g>
              <path
                style={{ fill: "#116466", fillOpacity: 1, stroke: "#2c3531", strokeWidth: 1.2227, strokeOpacity: 1}}
                d="m 83.40858,34.080995 -69.65161,33.080649 -0.605684,46.615906 24.193213,-10.41578 -0.373585,121.60071 -24.039731,-9.87177 0.757142,49.71917 95.272375,-0.80442 -0.13875,-49.22971 -25.783558,10.35742 -0.168549,-36.4689 61.417187,0.31475 c 0,0 -1.76045,74.8139 0.0538,74.85845 l 53.03793,-0.25675 -0.95181,-229.453004 -53.46517,-0.003 0.1082,76.215034 -59.553419,0.0112 z m 8.325061,88.869725 c 8.932649,0.0216 17.529469,9.20299 23.548179,14.25654 6.02081,-4.94025 14.36155,-14.04226 23.18688,-14.05073 17.60808,-0.005 31.88775,10.91932 31.89556,24.40041 0.10878,12.91661 -15.53154,24.34832 -31.88142,24.42225 -8.95286,-0.005 -17.35753,-8.19443 -23.39343,-13.25687 -6.00791,4.92431 -14.54036,13.03165 -23.341638,13.05105 -17.607788,0.002 -31.884153,-10.9244 -31.888474,-24.40525 -0.0044,-13.48141 14.265883,-24.4132 31.874343,-24.4174 z" />
              <ellipse
                style={{fill: "none", fillOpacity: 1, strokeWidth: 0.264583 }}
                ry="5.6622138"
                rx="5.6784978"
                cy="107.5882"
                cx="65.427773" />
            </g>
          </svg>
        </Link>
        <div className='menu-links-container'>
          <menu className='menu-links menu-links-top'>
            <li>
              <Link to={"/about"} onClick={openMenu}>{t("menu.about")}</Link>
            </li>
            {/* <li>
              <Link to={"/services"} onClick={openMenu} className="disabled-link">{t("menu.services")}</Link>
            </li> */}
            <li>
              <Link to={"/podcast"} onClick={openMenu}>{t("menu.podcast")}</Link>
            </li>
            <li>
              <a href={"https://hoosat.myspreadshop.fi/"} aria-label={t("menu.shop")} target="_blank" onClick={openMenu}>{t("menu.shop")}</a>
            </li>
            <li>
              <a href={"https://vuokrapaja.hoosat.fi/"} aria-label={t("menu.vuokrapaja")} target="_blank" onClick={openMenu}>{t("menu.vuokrapaja")}</a>
            </li>
            <li>
              <Link to={"/ranking"} aria-label={t("menu.ranking")} onClick={openMenu}>{t("menu.ranking")}</Link>
            </li>
            {/* <li>
              <Link to={"/recruit"} onClick={openMenu} className="disabled-link">{t("menu.recruit")}</Link>
            </li> */}
          </menu>
          <menu className='menu-links menu-links-bottom'>
            <li>
              <Link to={"/tietosuojaseloste"} aria-label={t("menu.tietosuojaseloste")} onClick={openMenu}>{t("menu.tietosuojaseloste")}</Link>
            </li>
            <li>
              <Link to={"/käyttöehdot"} aria-label={t("menu.käyttöehdot")} onClick={openMenu}>{t("menu.käyttöehdot")}</Link>
            </li>
            <li>
              <Link to={"/evästekäytäntö"} aria-label={t("menu.evästekäytäntö")} onClick={openMenu}>{t("menu.evästekäytäntö")}</Link>
            </li>
            <li>
              <Link to={"/gdpr"} aria-label={t("menu.gdpr")} onClick={openMenu}>{t("menu.gdpr")}</Link>
            </li>
          </menu>
        </div>
        <button className='menu-close-button' onClick={openMenu} aria-label='Menu close button'>
          <Icon name={'close'}></Icon>
        </button>
      </div>
    </div>
  );
};

