// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagebuilder {
  display: grid;
  gap: 20px;
  grid-template-areas:
    "header"
    "navigation"
    "content"
    "sidebar"
    "footer";
}

.pagebuilder>.header {
  grid-area: header;
}

.pagebuilder>.content {
  grid-area: content;
}

.pagebuilder>.navigation {
  grid-area: navigation;
}

.pagebuilder>.sidebar {
  grid-area: sidebar;
}

.pagebuilder>.footer {
  grid-area: footer;
}

@media (min-width: 768px) {
  .pagebuilder {
    grid-template-columns: 3fr 1fr;
    grid-template-areas:
      "header header"
      "navigation navigation"
      "content sidebar"
      "content sidebar"
      "footer footer";
  }

  .pagebuilder>.navigation>ul {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1280px) {
  .pagebuilder {
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-areas:
      "header header header"
      "navigation content sidebar"
      "navigation content sidebar"
      "footer footer footer";
  }

  .pagebuilder>.navigation>ul {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/client/HoosatUI/src/Builders/Page/PageBuilder.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT;;;;;YAKU;AACZ;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,8BAA8B;IAC9B;;;;;qBAKiB;EACnB;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;AACF;;AAEA;EACE;IACE,kCAAkC;IAClC;;;;4BAIwB;EAC1B;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".pagebuilder {\n  display: grid;\n  gap: 20px;\n  grid-template-areas:\n    \"header\"\n    \"navigation\"\n    \"content\"\n    \"sidebar\"\n    \"footer\";\n}\n\n.pagebuilder>.header {\n  grid-area: header;\n}\n\n.pagebuilder>.content {\n  grid-area: content;\n}\n\n.pagebuilder>.navigation {\n  grid-area: navigation;\n}\n\n.pagebuilder>.sidebar {\n  grid-area: sidebar;\n}\n\n.pagebuilder>.footer {\n  grid-area: footer;\n}\n\n@media (min-width: 768px) {\n  .pagebuilder {\n    grid-template-columns: 3fr 1fr;\n    grid-template-areas:\n      \"header header\"\n      \"navigation navigation\"\n      \"content sidebar\"\n      \"content sidebar\"\n      \"footer footer\";\n  }\n\n  .pagebuilder>.navigation>ul {\n    display: flex;\n    justify-content: space-between;\n  }\n}\n\n@media (min-width: 1280px) {\n  .pagebuilder {\n    grid-template-columns: 1fr 2fr 1fr;\n    grid-template-areas:\n      \"header header header\"\n      \"navigation content sidebar\"\n      \"navigation content sidebar\"\n      \"footer footer footer\";\n  }\n\n  .pagebuilder>.navigation>ul {\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
