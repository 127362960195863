// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!../theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageViewer {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.closeButton {
  position: fixed;
  z-index: 10010;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: var(--primary-color);
  border: 1px solid #000;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/client/HoosatUI/src/ImageViewer/ImageViewer.css"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,cAAc;EACd,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,sCAAsC;EACtC,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":["@import \"../theme.css\";\n\n.imageViewer {\n  position: fixed;\n  z-index: 10000;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n\n.closeButton {\n  position: fixed;\n  z-index: 10010;\n  bottom: 10px;\n  right: 10px;\n  padding: 5px 10px;\n  background-color: var(--primary-color);\n  border: 1px solid #000;\n  border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
