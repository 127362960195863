import React, { useState, useRef, useEffect } from 'react';

import "./VoiceRecorder.css";
import { useTranslation } from 'react-i18next';

interface VoiceRecorderProps {
  className: string;
  maxLength: number;
}

export const VoiceRecorder: React.FC<VoiceRecorderProps> = (props: VoiceRecorderProps) => {
  const [ t, i18n ] = useTranslation();
  const [isRecording, setIsRecording] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | MediaSource>();
  const [recordedTime, setRecordedTime] = useState(0);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleRecording = () => {
    if (!isRecording) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          recorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              setAudioBlob(e.data);
            }
          };
          recorder.onstop = () => {
            stream.getTracks().forEach((track) => track.stop());
          };

          recorder.onstart = () => {
            setRecordedTime(0);
            timerRef.current = setInterval(() => {
              setRecordedTime((prevTime) => prevTime + 1000);
            }, 1000);
          };

          mediaRecorder.current = recorder;
          recorder.start();
          setIsRecording(true);
        })
        .catch((error) => {
          console.error('Error accessing microphone:', error);
        });
    } else {
      stopRecording();
    }
  };

  const handleSendToServer = async () => {
    if (audioBlob) {
      const timestamp = new Date().toISOString().replace(/[^0-9]/g, '');
      const fileName = `voice_message_${timestamp}.mp3`;
      const formData = new FormData();
      formData.append('file[0]', audioBlob as Blob, fileName);
      try {
        const response = await fetch('/api/upload', {
          method: 'POST',
          body: formData,
        });
        if(response.ok) {
          cancelRecording();
          setIsSent(true);
        }
      } catch (error) {
        console.error('Error sending to server:', error);
      }
    }
  };

  const cancelRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      clearInterval(timerRef.current as NodeJS.Timeout);
      timerRef.current = null;
      setIsRecording(false);
      setAudioBlob(undefined); // Clear the recorded audio blob
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      clearInterval(timerRef.current as NodeJS.Timeout);
      timerRef.current = null;
    }
    setIsRecording(false);
  }

  useEffect(() => {
    const timeInSeconds = Math.floor(recordedTime / 1000);
    console.log("Recording: " + timeInSeconds + "s")
    if (timeInSeconds >= props.maxLength) {
      stopRecording();
      console.log("Automatically stopped recording.");
    }
  }, [recordedTime]);

  useEffect(() => {
    return () => {
      // Cleanup: Stop the recorder and clear the interval when the component is unmounted
      if (mediaRecorder.current) {
        mediaRecorder.current.stop();
      }
      clearInterval(timerRef.current as NodeJS.Timeout);
    };
  }, []);

  return (
    <div className={`recorder-container ${props.className}`}>
      { !audioBlob && 
        <button className='record-button' onClick={handleRecording} >
          {isRecording ? (
            <span>{`${t('voice-recorder.recording')} ${Math.floor(recordedTime / 1000)} s`}</span>
          ) : (
            <span>{t('voice-recorder.record')}</span>
          )}
        </button>  
      }
      { (audioBlob && !isSent) && (
        <div className="recording-container">
          <audio className='recorded-audio' controls src={URL.createObjectURL(audioBlob)} />
            <button className='cancel-record-button' onClick={cancelRecording}>{t('voice-recorder.cancel')}</button>
            <button className='send-record-button' onClick={handleSendToServer}>{t('voice-recorder.send')}</button>
        </div>
      )}
      { isSent && (
        <p className='recording-result'>{t('voice-recorder.result')}</p>
      )}
    </div>
  );
};
