// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js!../../../theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalFooter {
  padding-bottom: 1rem;
  width: 100%;
  justify-content: flex-end !important;
}

.ModalFooter > button {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/client/HoosatUI/src/Elements/Modal/Footer/ModalFooter.css"],"names":[],"mappings":"AAEA;EACE,oBAAoB;EACpB,WAAW;EACX,oCAAoC;AACtC;;AAEA;EACE,WAAW;AACb","sourcesContent":["@import '../../../theme.css';\n\n.ModalFooter {\n  padding-bottom: 1rem;\n  width: 100%;\n  justify-content: flex-end !important;\n}\n\n.ModalFooter > button {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
