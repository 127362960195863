import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import "./Podcast.css";
import { Link as ScrollLink } from 'react-scroll';
import { Icon } from '../HoosatUI';
import { VoiceRecorder } from '../Components/Common/VoiceRecorder/VoiceRecorder';

/**
 * Represents the Podcast component of the Hoosat SSR template.
 * This component displays the Podcast page content with a title and description.
 * It also includes a link to navigate back to the Home page.
 *
 * @returns {JSX.Element} The React component representing the Podcast page.
 */
export const Podcast = (): JSX.Element => {
  const [ t, i18n ] = useTranslation();
  const pageTitle = 'Hoosat Oy - Podcast';
  const pageDescription = 'Täällä vastaamme ääniviesteihin podcast muodossa. Tule ja kysy meiltä jotain teknologiaan liittyvää. Me teemme räätälöityä ohjelmistokehitystä ja verkkomarkkinointia sinun tarpeidesi mukaan. Ota yhteyttä! +358 44 937 5656 myynti@hoosat.fi';

  return (
    <main>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content="https://hoosat.fi/logo512.png" />
        <meta property="og:url" content="https://hoosat.fi/" />
      </Helmet>
      <section className='podcast-opening'>
        <h1>{t("podcast.header")}</h1>
        <VoiceRecorder className="voice-recorder" maxLength={60}></VoiceRecorder>
        <p className='voice-redorder-message'>{t("podcast.message")}</p>
      </section>
    </main>
  );
};

