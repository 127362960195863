// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ranking-opening {
  min-width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.ranking-opening > h1 {
  text-align: center;
  margin: 0px;
  color: #116466;
}

.ranking-form {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 35vw;
}

.ranking-message {
  text-align: center;
  width: 60vw;
  max-width: 80vw;
}`, "",{"version":3,"sources":["webpack://./src/client/routes/Ranking.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;AACjB","sourcesContent":[".ranking-opening {\n  min-width: 100%;\n  min-height: 100vh;\n  height: auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: left;\n}\n\n.ranking-opening > h1 {\n  text-align: center;\n  margin: 0px;\n  color: #116466;\n}\n\n.ranking-form {\n  margin-top: 50px;\n  margin-bottom: 50px;\n  width: 35vw;\n}\n\n.ranking-message {\n  text-align: center;\n  width: 60vw;\n  max-width: 80vw;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
