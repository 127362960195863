// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Anchor {
  font-weight: 500;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
.Anchor:hover {
  color: var(--active-color);
}
`, "",{"version":3,"sources":["webpack://./src/client/HoosatUI/src/Elements/Anchor/Anchor.css"],"names":[],"mappings":"AAEA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":["@import '../../theme';\n\n.Anchor {\n  font-weight: 500;\n  padding: 0.5rem 1rem;\n  font-size: 1rem;\n  line-height: 1.5rem;\n}\n.Anchor:hover {\n  color: var(--active-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
