// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Message {
  border-radius: 0.375rem;
}

.Message > p {
  margin: 0px;
}

.MessageTypeSuccess {
  background-color: var(--secondary-color);
  color: var(--text-color-mix);
}

.MessageTypeError {
  background-color: var(--secondary-color-dark);
  color: var(--text-color-mix);
}

.MessageTypeInfo {
  background-color: var(--secondary-color);
  color: var(--text-color-mix);
}`, "",{"version":3,"sources":["webpack://./src/client/HoosatUI/src/Elements/Message/Message.css"],"names":[],"mappings":"AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,wCAAwC;EACxC,4BAA4B;AAC9B;;AAEA;EACE,6CAA6C;EAC7C,4BAA4B;AAC9B;;AAEA;EACE,wCAAwC;EACxC,4BAA4B;AAC9B","sourcesContent":["@import '../../theme.css';\n\n.Message {\n  border-radius: 0.375rem;\n}\n\n.Message > p {\n  margin: 0px;\n}\n\n.MessageTypeSuccess {\n  background-color: var(--secondary-color);\n  color: var(--text-color-mix);\n}\n\n.MessageTypeError {\n  background-color: var(--secondary-color-dark);\n  color: var(--text-color-mix);\n}\n\n.MessageTypeInfo {\n  background-color: var(--secondary-color);\n  color: var(--text-color-mix);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
