import React, { useEffect, useRef, useState } from 'react';
import { Blinker } from '../Blinker';
import { text } from 'stream/consumers';

interface Props {
  texts: string[];
}

export const AnimatedTextArray: React.FC<Props> = (props) => {
  const [paragraphIndex, setParagraphIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const [textIndex, setTextIndex] = useState(0);
  const [direction, setDirection] = useState(1); // 1 for writing, -1 for removing
  const [intervalTime, setIntervalTime] = useState(150);
  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.5 } // Määritä näkyvyystaso (esim. 0.5 tarkoittaa, että yli puolet elementistä näkyy)
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        if(direction === 1) {
          setDisplayedText(displayedText + props.texts[paragraphIndex][textIndex]);
          setTextIndex(textIndex + 1);
          setIntervalTime(150);
          if(textIndex === props.texts[paragraphIndex].length - 1) {
            setDirection(-1);
          }
        } else {
          setDisplayedText(displayedText.slice(0, textIndex - 1));
          setTextIndex(textIndex - 1);
          setIntervalTime(50);
          if(textIndex === 1) {
            setDirection(1);
            if (paragraphIndex < props.texts.length - 1) {
              setParagraphIndex(paragraphIndex + 1);
            } else {
              setParagraphIndex(0);
            }
          }
        }
      }, intervalTime);
      return () => {
        clearInterval(interval);
      }
    }
    return;
  }, [isVisible, direction, displayedText, textIndex, paragraphIndex, props.texts, intervalTime])

  return (
    <p ref={targetRef}>
      {displayedText}
      <Blinker />
    </p>
  );
};

export const AnimatedText: React.FC<Props> = (props) => {
  const [displayedText, setDisplayedText] = useState("");
  const [textIndex, setTextIndex] = useState(0);
  const [direction, setDirection] = useState(1); // 1 for writing, -1 for removing
  const [intervalTime, setIntervalTime] = useState(250);

  useEffect(() => {
    const interval = setInterval(() => {
      if(direction === 1) {
        setDisplayedText(displayedText + props.texts[textIndex]);
        setTextIndex(textIndex + 1);
        setIntervalTime(250);
        if(textIndex === props.texts.length - 1) {
          setDirection(-1);
        }
      } else {
        setDisplayedText(displayedText.slice(0, textIndex - 1));
        setTextIndex(textIndex - 1);
        setIntervalTime(50);
        if(textIndex === 1) {
          setDirection(1);
        }
      }
    }, intervalTime);
    return () => {
      clearInterval(interval);
    }
  })

  return (
    <p>
      {displayedText}
      <Blinker />
    </p>
  );
};