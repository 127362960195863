import React, { useState } from 'react';
import { FormBuilder, Icon } from '../../../HoosatUI';

import "./ContactForm.css";
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';

interface ContactFormDTO {
  email: string,
  message: string,
  marketing: boolean,
}

interface ContactFormProps {
  nextElement: string,
  nextDirection: "up" | "down",
  className: string,
}
  
export const ContactForm = (props: ContactFormProps) => {
  const [ t, i18n ] = useTranslation();

  const [contactFrom, setContactForm] = useState<ContactFormDTO>({
    email: "",
    message: "",
    marketing: true
  })

  const resetSubmitButtonColor = async () => {
    const submitButton = document.getElementById('contact-form-submit-button');
    if (submitButton) {
      submitButton.style.backgroundColor = '#262626';
    }
  }

  const submitContactForm = async () => {
    console.log(contactFrom);
    const submitButton = document.getElementById('contact-form-submit-button');
    const body = {
      from: contactFrom?.email,
      message: contactFrom?.message,
      marketing: contactFrom?.marketing === true ? "true" : "false",
    }
    const uri = `/api/contact/meeting`
    const response = await fetch(uri, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        'Accept': 'application/json',
      },
      body: JSON.stringify(body)
    });
    if (response.ok) {
      setContactForm({
        email: "",
        message: "",
        marketing: true
      });
      if (submitButton) {
        submitButton.style.backgroundColor = '#4CAF50';
        /* Possible success colors.
         * #4CAF50 green
         * #4CAF50 blue
         * #FFD700 gold/yellow
         */
      }
    } else {
      if (submitButton) {
        submitButton.style.backgroundColor = '#80080';
        /* Possible success colors.
         * #FF0000 red
         * #FFA500 orange
         * #800080 dark purple
         */
      }
    }
  }

  return (
    <section className={`contact-form ${props.className}`}>
      <h1>{t("landing.contact-form.h1")}</h1>
      <FormBuilder 
        submitbuttonid={"contact-form-submit-button"}
        submitbuttontext={t("landing.contact-form.send-button")} 
        inputs={[
          {
            itype: "input", 
            id: "contact-form-email", 
            label: t("landing.contact-form.email-input-label"),
            type: "text", 
            placeholder: t("landing.contact-form.email-input-placeholder"), 
            onChange: (e: React.BaseSyntheticEvent) => { resetSubmitButtonColor(); setContactForm({...contactFrom, email: e.target.value})},
            value: contactFrom.email,
          },
          {
            itype: "textarea", 
            id: "contact-form-message", 
            label: t("landing.contact-form.message-input-label"),
            type: "text", 
            placeholder: t("landing.contact-form.message-input-placeholder"), 
            onChange: (e: React.BaseSyntheticEvent) => { resetSubmitButtonColor(); setContactForm({...contactFrom, message: e.target.value})},
            value: contactFrom.message,
          },
          {
            itype: "input", 
            id: "contact-form-marketing", 
            label: t("landing.contact-form.marketing-input-label"),
            type: "checkbox", 
            title: t("landing.contact-form.marketing-input-title"),
            onChange: (e: React.BaseSyntheticEvent) => { resetSubmitButtonColor(); setContactForm({...contactFrom, marketing: e.target.checked})},
            checked: contactFrom.marketing,
          },
        ]}
        onSubmit={submitContactForm}>
      </FormBuilder>
      <ScrollLink
        href={`#${props.nextElement}`}
        to={`${props.nextElement}`}
        smooth={true}
        duration={1200}
        aria-label={`Scroll to ${props.nextElement.replace(new RegExp('-', 'g'), " ")}.`}
        className={`scroll-${props.nextDirection}-button`}>
        <Icon className="scroll-down-button-icon" name={`arrow-thick-${props.nextDirection}`}></Icon>
      </ScrollLink>
    </section>
  );
};

