

import React, { useCallback, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { About } from './routes/About';
import { Home } from './routes/Home';
import { Helmet } from 'react-helmet-async';
import { Menu } from './Components/Menu/Index';

import { loadSlim } from "tsparticles-slim";
import Particles from "react-tsparticles";
import type { Container, Engine } from "tsparticles-engine";

import "./App.css";
import { Podcast } from './routes/Podcast';
import { Ranking } from './routes/Ranking';
import { Privacy } from './routes/Privacy';
import { Terms } from './routes/Terms';
import { CookiePolicy } from './routes/CookiePolicy';
import { GDPRPolicy } from './routes/GDPRPolicy';
/**
 * Represents the main component of the client-side React application.
 * It defines the routes for the app using the Routes and Route components from react-router-dom.
 * The Home component is rendered when the URL path is "/" and the About component is rendered when
 * the URL path is "/about".
 *
 * @returns {JSX.Element} The React component that defines the app routes.
 */
export const App = (): JSX.Element => {
  const particlesInit = useCallback(async (engine: Engine) => {
    console.log(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
    console.log(container);
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }
  }, []);
  return (
    <>
      <Helmet>
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#116466" />
        <meta name="viewport" content="width=device-width" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/podcast" element={<Podcast />} />
        <Route path="/ranking" element={<Ranking />} />
        <Route path="/tietosuojaseloste" element={<Privacy />} />
        <Route path="/käyttöehdot" element={<Terms />} />
        <Route path="/evästekäytäntö" element={<CookiePolicy />} />
        <Route path="/gdpr" element={<GDPRPolicy />} />
      </Routes>
      <Menu></Menu>
      <Particles id="tsparticles" url="particles.json" init={particlesInit} loaded={particlesLoaded} />
    </>
  );
}