// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  order: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  justify-content: flex-start;
  align-self: auto;
  align-items: flex-start;
  align-content: flex-start;
  gap: normal;
  row-gap: normal;
  column-gap: normal;
}

.FlexItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  order: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  justify-content: flex-start;
  align-self: auto;
  align-items: flex-start;
  align-content: flex-start;
  gap: normal;
  row-gap: normal;
  column-gap: normal;
}
.FlexItem > * {
  display: block;
}

`, "",{"version":3,"sources":["webpack://./src/client/HoosatUI/src/Elements/Flex/Flex.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,QAAQ;EACR,YAAY;EACZ,cAAc;EACd,gBAAgB;EAChB,2BAA2B;EAC3B,gBAAgB;EAChB,uBAAuB;EACvB,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,QAAQ;EACR,YAAY;EACZ,cAAc;EACd,gBAAgB;EAChB,2BAA2B;EAC3B,gBAAgB;EAChB,uBAAuB;EACvB,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB","sourcesContent":["@import '../../theme';\n\n.Flex {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  order: 0;\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-basis: auto;\n  justify-content: flex-start;\n  align-self: auto;\n  align-items: flex-start;\n  align-content: flex-start;\n  gap: normal;\n  row-gap: normal;\n  column-gap: normal;\n}\n\n.FlexItem {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  order: 0;\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-basis: auto;\n  justify-content: flex-start;\n  align-self: auto;\n  align-items: flex-start;\n  align-content: flex-start;\n  gap: normal;\n  row-gap: normal;\n  column-gap: normal;\n}\n.FlexItem > * {\n  display: block;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
