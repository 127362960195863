// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CookiePolicy-opening {
  min-width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.CookiePolicy-opening > svg {
  display: block;
  width: calc(490px * 0.85);
  height: calc(637px * 0.85);
}

.CookiePolicy-opening > h1 {
  margin: 0px;
  color: #116466;
}
.CookiePolicy-opening > p {
  width: 80vw;
  text-align: center;
}

.CookiePolicy-section {
  min-width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.CookiePolicy-section > h1 {
  max-width: 60%;
}
.CookiePolicy-section > p {
  max-width: 60%;
}


@media (max-width: 768px) {
}

`, "",{"version":3,"sources":["webpack://./src/client/routes/CookiePolicy.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,cAAc;AAChB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;;;AAGA;AACA","sourcesContent":[".CookiePolicy-opening {\n  min-width: 100%;\n  min-height: 100vh;\n  height: auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: left;\n}\n\n.CookiePolicy-opening > svg {\n  display: block;\n  width: calc(490px * 0.85);\n  height: calc(637px * 0.85);\n}\n\n.CookiePolicy-opening > h1 {\n  margin: 0px;\n  color: #116466;\n}\n.CookiePolicy-opening > p {\n  width: 80vw;\n  text-align: center;\n}\n\n.CookiePolicy-section {\n  min-width: 100%;\n  min-height: 100vh;\n  height: auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: left;\n}\n\n.CookiePolicy-section > h1 {\n  max-width: 60%;\n}\n.CookiePolicy-section > p {\n  max-width: 60%;\n}\n\n\n@media (max-width: 768px) {\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
