// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../public/images/third.avif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.about-us > h1 {
  color: #116466;
}

.about-us > p {
  overflow-wrap: normal;
  word-wrap: normal;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  text-align: center;
  color: rgb(183, 183, 183);
  width: 768px;
  height: 447px;
  font-size: 2em;
  border-radius: 10px;
  -webkit-box-shadow: -7px 6px 14px 1px rgba(0,0,0,0.39);
  -moz-box-shadow: -7px 6px 14px 1px rgba(0,0,0,0.39);
  box-shadow: -7px 6px 14px 1px rgba(0,0,0,0.39);
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/client/Components/Landing/AboutUs/AboutUs.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,yDAAgE;EAChE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,sDAAsD;EACtD,mDAAmD;EACnD,8CAA8C;EAC9C,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".about-us {\n  width: 100%;\n  height: auto;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n}\n\n.about-us > h1 {\n  color: #116466;\n}\n\n.about-us > p {\n  overflow-wrap: normal;\n  word-wrap: normal;\n  background-image: url(\"../../../../../public/images/third.avif\");\n  text-align: center;\n  color: rgb(183, 183, 183);\n  width: 768px;\n  height: 447px;\n  font-size: 2em;\n  border-radius: 10px;\n  -webkit-box-shadow: -7px 6px 14px 1px rgba(0,0,0,0.39);\n  -moz-box-shadow: -7px 6px 14px 1px rgba(0,0,0,0.39);\n  box-shadow: -7px 6px 14px 1px rgba(0,0,0,0.39);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
