// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Grid {
  display: grid;
  gap: 1rem;
  grid-auto-flow: dense;
  grid-template-areas: none;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.GridItem {
  grid-column-start: auto;
  grid-column-end: auto;
  grid-row-start: auto;
  grid-row-start: auto;
  grid-area: auto;
}

`, "",{"version":3,"sources":["webpack://./src/client/HoosatUI/src/Elements/Grid/Grid.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,SAAS;EACT,qBAAqB;EACrB,yBAAyB;EACzB,2BAA2B;EAC3B,wBAAwB;AAC1B;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,oBAAoB;EACpB,oBAAoB;EACpB,eAAe;AACjB","sourcesContent":["@import '../../theme';\n\n.Grid {\n  display: grid;\n  gap: 1rem;\n  grid-auto-flow: dense;\n  grid-template-areas: none;\n  grid-template-columns: auto;\n  grid-template-rows: auto;\n}\n\n.GridItem {\n  grid-column-start: auto;\n  grid-column-end: auto;\n  grid-row-start: auto;\n  grid-row-start: auto;\n  grid-area: auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
