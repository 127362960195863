import React from 'react';
export const Specializing = () => {
  return (
    <div className='specialicing'>
      <a href="https://nodejs.org/"><img src="/StackLogos/nodejs-icon.svg" alt="Node.js" width="64" height="64"></img></a>
      <a href="https://www.typescriptlang.org/"><img src="/StackLogos/typescriptlang-icon.svg" alt="Typescript" width="64" height="64"></img></a>
      <a href="https://react.dev/"><img src="/StackLogos/reactjs-icon.svg" alt="React" width="64" height="64"></img></a>
      <a href="https://www.mongodb.com/"><img src="/StackLogos/mongodb-icon.svg" alt="MongoDB" width="64" height="64"></img></a>
      <a href="https://git-scm.com/"><img src="/StackLogos/git-scm-icon.svg" alt="Git Source Code manager" width="64" height="64"></img></a>
      <a href="https://www.docker.com/"><img src="/StackLogos/docker-icon.svg" alt="Docker" width="64" height="64"></img></a>
      <a href="https://archlinux.org/"><img src="/StackLogos/archlinux-icon.svg" alt="Arch Linux" width="64" height="64"></img></a>
      <a href="https://www.mysql.com/"><img src="/StackLogos/mysql-icon.svg" alt="MySQL" width="64" height="64"></img></a>
      <a href="https://www.postgresql.org/"><img src="/StackLogos/postgresql-icon.svg" alt="PostgreSQL" width="64" height="64"></img></a>
      <a href="https://code.visualstudio.com/"><img src="/StackLogos/visualstudio_code-icon.svg" alt="Visual Studio Code" width="64" height="64"></img></a>
    </div>
  )
}