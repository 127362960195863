// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recorder-container {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.record-button { 
  width: 300px;
  height: 54px; 
  border: 0px;
  border-radius: 50px;
  overflow: hidden;
  background-color: #116466;
}

.recording-container {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.send-record-button { 
  width: 300px;
  height: 54px; 
  border: 0px;
  border-radius: 50px;
  overflow: hidden;
  background-color: #116466;
}

.cancel-record-button {
  width: 300px;
  height: 54px; 
  border: 0px;
  border-radius: 50px;
  overflow: hidden;
  background-color: #116466;
  color: red;
}

audio::-webkit-media-controls-panel {
  background-color: #116466 !important;
}

.recording-result {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

`, "",{"version":3,"sources":["webpack://./src/client/Components/Common/VoiceRecorder/VoiceRecorder.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".recorder-container {\n  max-width: 300px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.record-button { \n  width: 300px;\n  height: 54px; \n  border: 0px;\n  border-radius: 50px;\n  overflow: hidden;\n  background-color: #116466;\n}\n\n.recording-container {\n  display: flex;\n  gap: 5px;\n  flex-direction: column;\n}\n\n.send-record-button { \n  width: 300px;\n  height: 54px; \n  border: 0px;\n  border-radius: 50px;\n  overflow: hidden;\n  background-color: #116466;\n}\n\n.cancel-record-button {\n  width: 300px;\n  height: 54px; \n  border: 0px;\n  border-radius: 50px;\n  overflow: hidden;\n  background-color: #116466;\n  color: red;\n}\n\naudio::-webkit-media-controls-panel {\n  background-color: #116466 !important;\n}\n\n.recording-result {\n  text-align: center;\n  padding-left: 15px;\n  padding-right: 15px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
