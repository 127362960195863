import React, { useState } from 'react';
import { FormBuilder, Icon } from '../../../HoosatUI';

import "./ElevatorPitch.css";
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';

interface ElevatorPitchDTO {
  email: string,
  message: string,
  marketing: boolean,
}

interface ElevatorPitchProps {
  nextElement: string,
  nextDirection: "up" | "down",
  className: string,
}
  
export const ElevatorPitch = (props: ElevatorPitchProps) => {
  const [ t, i18n ] = useTranslation();

  return (
    <section className={`elevator-pitch ${props.className}`}>
      <h1>{t("landing.elevator.h1")}</h1>
      <div className='picture-and-text'>
        <img src="/images/renderer-code.avif" alt="Hoosat Create Renderer" width="900" height="730"></img>
        <div>
          <p>{t("landing.elevator.p1")}</p>
          <p>{t("landing.elevator.p2")}</p>
          <p>{t("landing.elevator.p3")}</p>
          <p>{t("landing.elevator.p4")}</p>
          <p>{t("landing.elevator.p5")}</p>
        </div>
      </div>
      <ScrollLink
        href={`#${props.nextElement}`}
        to={`${props.nextElement}`}
        smooth={true}
        duration={1200}
        aria-label={`Scroll to ${props.nextElement.replace(new RegExp('-', 'g'), " ")}.`}
        className={`scroll-${props.nextDirection}-button`}>
        <Icon className="scroll-down-button-icon" name={`arrow-thick-${props.nextDirection}`}></Icon>
      </ScrollLink>
    </section>
  );
};

