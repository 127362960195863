import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import "./About.css";
import { Link as ScrollLink } from 'react-scroll';
import { Icon } from '../HoosatUI';

/**
 * Represents the About component of the Hoosat SSR template.
 * This component displays the about page content with a title and description.
 * It also includes a link to navigate back to the Home page.
 *
 * @returns {JSX.Element} The React component representing the About page.
 */


export const About = (): JSX.Element => {
  const [ t, i18n ] = useTranslation();
  const pageTitle = 'Hoosat Oy - About us';
  const pageDescription = 'Täällä kerromme vähän meistä tiiminä. Me teemme räätälöityä ohjelmistokehitystä ja verkkomarkkinointia sinun tarpeidesi mukaan. Ota yhteyttä! +358 44 937 5656 myynti@hoosat.fi';

  return (
    <main>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content="https://hoosat.fi/logo512.png" />
        <meta property="og:url" content="https://hoosat.fi/" />
      </Helmet>
      <section className='about-opening'>
        <svg
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
          version="1.1"
          id="hoosat-about-svg">
          <g>
            <path
              style={{ fill: "#116466", fillOpacity: 1, stroke: "#2c3531", strokeWidth: 1.2227, strokeOpacity: 1}}
              d="m 83.40858,34.080995 -69.65161,33.080649 -0.605684,46.615906 24.193213,-10.41578 -0.373585,121.60071 -24.039731,-9.87177 0.757142,49.71917 95.272375,-0.80442 -0.13875,-49.22971 -25.783558,10.35742 -0.168549,-36.4689 61.417187,0.31475 c 0,0 -1.76045,74.8139 0.0538,74.85845 l 53.03793,-0.25675 -0.95181,-229.453004 -53.46517,-0.003 0.1082,76.215034 -59.553419,0.0112 z m 8.325061,88.869725 c 8.932649,0.0216 17.529469,9.20299 23.548179,14.25654 6.02081,-4.94025 14.36155,-14.04226 23.18688,-14.05073 17.60808,-0.005 31.88775,10.91932 31.89556,24.40041 0.10878,12.91661 -15.53154,24.34832 -31.88142,24.42225 -8.95286,-0.005 -17.35753,-8.19443 -23.39343,-13.25687 -6.00791,4.92431 -14.54036,13.03165 -23.341638,13.05105 -17.607788,0.002 -31.884153,-10.9244 -31.888474,-24.40525 -0.0044,-13.48141 14.265883,-24.4132 31.874343,-24.4174 z" />
            <ellipse
              style={{fill: "none", fillOpacity: 1, strokeWidth: 0.264583 }}
              ry="5.6622138"
              rx="5.6784978"
              cy="107.5882"
              cx="65.427773" />
          </g>
        </svg>
        <h1>Hoosat Oy</h1>
        <p>{t("about.opening.sub")}</p>
        <ScrollLink
          href='#about-toni'
          to="about-toni"
          smooth={true}
          duration={1200}
          aria-label="Scroll down to the about us."
          className="scroll-down-button">
          <Icon className="scroll-down-button-icon" name={'arrow-thick-down'}></Icon>
        </ScrollLink>
      </section>
      <section className='about-toni'>
        <div className='about-container'>
          <img src="/images/toni.jpeg" height="800" width="800" crossOrigin='anonymous'></img>
          <div className='about-data'>
            <h2>Toni Lukkaroinen</h2>
            <h3>{t("about.toni.title")}</h3>
            <p>{t("about.phone")}<a href="tel:+358 44 937 5656">+358 44 937 5656</a></p>
            <p>{t("about.email")}<a href="mailto:toni.lukkaroinen@hoosat.fi">toni.lukkaroinen@hoosat.fi</a></p>
            <p>{t("about.toni.description")}</p>
          </div>
        </div>
        <ScrollLink
          href='#about-harri'
          to="about-harri"
          smooth={true}
          duration={1200}
          aria-label="Scroll down to the about us."
          className="scroll-down-button">
          <Icon className="scroll-down-button-icon" name={'arrow-thick-down'}></Icon>
        </ScrollLink>
      </section>
      <section className='about-harri'>
        <div className='about-container'>
          <img src="/images/harri.jpeg" height="800" width="800" crossOrigin='anonymous'></img>
          <div className='about-data'>
            <h2>Harri Luosujärvi</h2>
            <h3>{t("about.harri.title")}</h3>
            <p>{t("about.phone")}<a href="tel:+358 40 066 0691">+358 40 066 0691</a></p>
            <p>{t("about.email")}<a href="mailto:harri.luosujarvi@hoosat.fi">harri.luosujarvi@hoosat.fi</a></p>
            <p>{t("about.harri.description")}</p>
          </div>
        </div>
          <ScrollLink
            href='#about-anssi'
            to="about-anssi"
            smooth={true}
            duration={1200}
            aria-label="Scroll to back up to the opening."
            className="scroll-down-button">
            <Icon className="scroll-down-button-icon" name={'arrow-thick-down'}></Icon>
          </ScrollLink>
      </section>
      {/* <section className='about-anssi'>
        <div className='about-container'>
          <img src="/images/anssi.jpg" height="800" width="800" crossOrigin='anonymous'></img>
          <div className='about-data'>
            <h2>Anssi Mäkelä</h2>
            <h3>{t("about.anssi.title")}</h3>
            <p>{t("about.phone")}<a href="tel:+358 40 629 0851">+358 40 629 0851</a></p>
            <p>{t("about.email")}<a href="mailto:anssi.makela@hoosat.fi">anssi.makela@hoosat.fi</a></p>
            <div className="multi-description">
              {(t("about.anssi.description", { returnObjects: true }) as string[]).map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
        </div>
          <ScrollLink
            href='#about-simon'
            to="about-simon"
            smooth={true}
            duration={1200}
            aria-label="Scroll to back up to the opening."
            className="scroll-down-button">
            <Icon className="scroll-down-button-icon" name={'arrow-thick-down'}></Icon>
          </ScrollLink>
      </section> */}
      {/* <section className='about-simon'>
        <div className='about-container'>
          <img src="/images/simon.jpg" height="800" width="800" crossOrigin='anonymous'></img>
          <div className='about-data'>
            <h2>Simon J Kok</h2>
            <h3>{t("about.simon.title")}</h3>
            <p>{t("about.phone")}<a href="tel:+358 45 650 8398">+358 45 650 8398</a></p>
            <p>{t("about.email")}<a href="mailto:simon.j.kok@hoosat.fi">simon.j.kok@hoosat.fi</a></p>
            <p>{t("about.simon.description")}</p>
          </div>
        </div>
          <ScrollLink
            href='#about-opening'
            to="about-opening"
            smooth={true}
            duration={1200}
            aria-label="Scroll to back up to the opening."
            className="scroll-down-button">
            <Icon className="scroll-down-button-icon" name={'arrow-thick-up'}></Icon>
          </ScrollLink>
      </section> */}
    </main>
  );
};

