// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.List {
  font-weight: 500;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  list-style-position: inside;
}

.ListItem {
  font-weight: 500;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
.ListItem:hover {
  color: var(--focus-color);
}
`, "",{"version":3,"sources":["webpack://./src/client/HoosatUI/src/Elements/List/List.css"],"names":[],"mappings":"AAEA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,eAAe;EACf,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,yBAAyB;AAC3B","sourcesContent":["@import '../../theme';\n\n.List {\n  font-weight: 500;\n  padding: 0.5rem 1rem;\n  font-size: 1rem;\n  line-height: 1.5rem;\n  list-style-position: inside;\n}\n\n.ListItem {\n  font-weight: 500;\n  padding: 0.5rem 1rem;\n  font-size: 1rem;\n  line-height: 1.5rem;\n}\n.ListItem:hover {\n  color: var(--focus-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
