// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js!../../../theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalHeader {
  width: 100%;
  padding: 0px;
  align-items: center !important;
  justify-content: space-between !important;
}

.ModalHeader > h1,
.ModalHeader > h2,
.ModalHeader > h3,
.ModalHeader > h4,
.ModalHeader > h5,
.ModalHeader > h6 {
  padding-left: 0px;
}`, "",{"version":3,"sources":["webpack://./src/client/HoosatUI/src/Elements/Modal/Header/ModalHeader.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,yCAAyC;AAC3C;;AAEA;;;;;;EAME,iBAAiB;AACnB","sourcesContent":["@import '../../../theme.css';\n\n.ModalHeader {\n  width: 100%;\n  padding: 0px;\n  align-items: center !important;\n  justify-content: space-between !important;\n}\n\n.ModalHeader > h1,\n.ModalHeader > h2,\n.ModalHeader > h3,\n.ModalHeader > h4,\n.ModalHeader > h5,\n.ModalHeader > h6 {\n  padding-left: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
