import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import "./Ranking.css";
import { Link as ScrollLink } from 'react-scroll';
import { FormBuilder } from '../HoosatUI';

interface RankingFormDTO {
  email: string,
  web: string,
  phone: string,
  marketing: string,
}

export const Ranking = (): JSX.Element => {
  const [ t, i18n ] = useTranslation();
  const pageTitle = 'Hoosat Oy - Website Ranking';
  const pageDescription = 'Testaa miten kotisivusi pärjää muiden joukossa. Ota yhteyttä jos petyit tuloksiisi! +358 44 937 5656 myynti@hoosat.fi';
  const [ rankingForm, setRankingForm] = useState<RankingFormDTO>({
    email: "",
    web: "",
    phone: "",
    marketing: "",
  });

  const submitRankingForm = async () => {

  }

  return (
    <main>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content="https://hoosat.fi/logo512.png" />
        <meta property="og:url" content="https://hoosat.fi/" />
      </Helmet>
      <section className='ranking-opening'>
        <h1>{t("ranking.header")}</h1>
        <FormBuilder 
          className='ranking-form'
          submitbuttontext={t("ranking.send-button")} 
          inputs={[
            {
              itype: "input", 
              id: "ranking-web", 
              label: t("ranking.web-input-label"),
              type: "text", 
              placeholder: t("ranking.web-input-placeholder"), 
              onChange: (e: React.BaseSyntheticEvent) => { setRankingForm({...rankingForm, web: e.target.value})},
              value: rankingForm.web,
            },
            {
              itype: "input", 
              id: "ranking-email", 
              label: t("ranking.email-input-label"),
              type: "text", 
              placeholder: t("ranking.email-input-placeholder"), 
              onChange: (e: React.BaseSyntheticEvent) => { setRankingForm({...rankingForm, email: e.target.value})},
              value: rankingForm.email,
            },
            {
              itype: "input", 
              id: "ranking-phone", 
              label: t("ranking.phone-input-label"),
              type: "text", 
              placeholder: t("ranking.phone-input-placeholder"), 
              onChange: (e: React.BaseSyntheticEvent) => { setRankingForm({...rankingForm, phone: e.target.value})},
              value: rankingForm.phone,
            },
            {
              itype: "input", 
              id: "ranking-marketing", 
              label: t("ranking.marketing-input-label"),
              type: "checkbox", 
              title: t("ranking.marketing-input-title"),
              onChange: (e: React.BaseSyntheticEvent) => { setRankingForm({...rankingForm, marketing: e.target.checked})},
              checked: rankingForm.marketing,
            },
          ]} 
          onSubmit={submitRankingForm}
        />
        <p className='ranking-message'>{t("ranking.message")}</p>
      </section>
    </main>
  );
};

