import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import "./Privacy.css";
import { Link as ScrollLink } from 'react-scroll';
import { Icon } from '../HoosatUI';

/**
 * Represents the About component of the Hoosat SSR template.
 * This component displays the about page content with a title and description.
 * It also includes a link to navigate back to the Home page.
 *
 * @returns {JSX.Element} The React component representing the About page.
 */


export const Privacy = (): JSX.Element => {
  const [ t, i18n ] = useTranslation();
  const pageTitle = 'Hoosat Oy - About us';
  const pageDescription = 'Täällä kerromme vähän meistä tiiminä. Me teemme räätälöityä ohjelmistokehitystä ja verkkomarkkinointia sinun tarpeidesi mukaan. Ota yhteyttä! +358 44 937 5656 myynti@hoosat.fi';

  return (
    <main>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content="https://hoosat.fi/logo512.png" />
        <meta property="og:url" content="https://hoosat.fi/" />
      </Helmet>
      <section className='privacy-policy-opening'>
        <h1>{t("privacyPolicy.title")}</h1>
        <p>{t("privacyPolicy.introduction")}</p>
      </section>
      <section className='privacy-policy-collectionOfData'>
        <h1>{t("privacyPolicy.collectionOfData.title")}</h1>
        <p>{t("privacyPolicy.collectionOfData.description")}</p>
      </section>
      <section className='privacy-policy-useOfData'>
        <h1>{t("privacyPolicy.useOfData.title")}</h1>
        <p>{t("privacyPolicy.useOfData.description")}</p>
      </section>
      <section className='privacy-policy-protectionOfData'>
        <h1>{t("privacyPolicy.protectionOfData.title")}</h1>
        <p>{t("privacyPolicy.protectionOfData.description")}</p>
      </section>
      <section className='privacy-policy-rightsOfDataSubjects'>
        <h1>{t("privacyPolicy.rightsOfDataSubjects.title")}</h1>
        <p>{t("privacyPolicy.rightsOfDataSubjects.description")}</p>
      </section>
      <section className='privacy-policy-contactInformation'>
        <h1>{t("privacyPolicy.contactInformation.title")}</h1>
        <p>{t("privacyPolicy.contactInformation.description")}</p>
      </section>
    </main>
  );
};

