// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.contact-form {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contact-form > h1 {
  color: #116466;
}

.contact-form > form {
  width: 768px;
}

.contact-form > form > div > label {
  text-align: left;
}

.contact-form > form > div > textarea {
  height: 270px;
}


@media (max-width: 1360px) {
  .contact-form > form {
    max-width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/client/Components/Landing/ContactForm/ContactForm.css"],"names":[],"mappings":";;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;;AAGA;EACE;IACE,4BAA4B;IAC5B,iBAAiB;IACjB,kBAAkB;EACpB;AACF","sourcesContent":["\n\n.contact-form {\n  width: 100%;\n  height: auto;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n}\n\n.contact-form > h1 {\n  color: #116466;\n}\n\n.contact-form > form {\n  width: 768px;\n}\n\n.contact-form > form > div > label {\n  text-align: left;\n}\n\n.contact-form > form > div > textarea {\n  height: 270px;\n}\n\n\n@media (max-width: 1360px) {\n  .contact-form > form {\n    max-width: calc(100% - 40px);\n    margin-left: 20px;\n    margin-right: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
