import React from 'react';
import { Icon } from '../../../HoosatUI';

import "./AboutUs.css";
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { AnimatedTextArray } from '../../Common/AnimatedText/AnimatedText';

interface AboutUsDTO {
  email: string,
  message: string,
  marketing: boolean,
}

interface AboutUsProps {
  nextElement: string,
  nextDirection: "up" | "down",
  className: string,
}
  
export const AboutUs = (props: AboutUsProps) => {
  const [ t, i18n ] = useTranslation();

  return (
    <section className={`about-us ${props.className}`}>
      <h1>{t("landing.about-us.h1")}</h1>
      <AnimatedTextArray texts={(t("landing.about-us.animated", { returnObjects: true }) as unknown as string[])}/>
      <ScrollLink
        href={`#${props.nextElement}`}
        to={`${props.nextElement}`}
        smooth={true}
        duration={1200}
        aria-label={`Scroll to ${props.nextElement.replace(new RegExp('-', 'g'), " ")}.`}
        className={`scroll-${props.nextDirection}-button`}>
        <Icon className="scroll-down-button-icon" name={`arrow-thick-${props.nextDirection}`}></Icon>
      </ScrollLink>
    </section>
  );
};

