// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js!../../theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalContainer {
  background-color: var(--secondary-color);
  position: fixed;
  top: 5%;
  left: 25%;
  max-height: 90%;
  width: 50%;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.ModalInner {
  position: relative;
  width: 100%;
  box-shadow: none;
}

.ModalContent {
  width: auto;
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/client/HoosatUI/src/Elements/Modal/Modal.css"],"names":[],"mappings":"AAEA;EACE,wCAAwC;EACxC,eAAe;EACf,OAAO;EACP,SAAS;EACT,eAAe;EACf,UAAU;EACV,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,qBAAqB;EACrB,uLAAuL;AACzL;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":["@import '../../theme.css';\n\n.ModalContainer {\n  background-color: var(--secondary-color);\n  position: fixed;\n  top: 5%;\n  left: 25%;\n  max-height: 90%;\n  width: 50%;\n  outline: none;\n  overflow-x: hidden;\n  overflow-y: auto;\n  padding: 15px;\n  border-radius: 0.5rem;\n  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;\n}\n\n.ModalInner {\n  position: relative;\n  width: 100%;\n  box-shadow: none;\n}\n\n.ModalContent {\n  width: auto;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
