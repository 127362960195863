// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.podcast-opening {
  min-width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.podcast-opening > h1 {
  margin: 0px;
  color: #116466;
}

.voice-redorder-message {
  width: 60vw;
  max-width: 80vw;
}
.podcast-opening > .voice-recorder {
  margin-top: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/client/routes/Podcast.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".podcast-opening {\n  min-width: 100%;\n  min-height: 100vh;\n  height: auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: left;\n}\n\n.podcast-opening > h1 {\n  margin: 0px;\n  color: #116466;\n}\n\n.voice-redorder-message {\n  width: 60vw;\n  max-width: 80vw;\n}\n.podcast-opening > .voice-recorder {\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
