import React from 'react';
import { Icon } from '../../../HoosatUI';

import "./TheStack.css";
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { Specializing } from './Specializing';

interface TheStackDTO {
  email: string,
  message: string,
  marketing: boolean,
}

interface TheStackProps {
  nextElement: string,
  nextDirection: "up" | "down",
  className: string,
}
  
export const TheStack = (props: TheStackProps) => {
  const [ t, i18n ] = useTranslation();

  return (
    <section className={`the-stack ${props.className}`}>
      <h1>{t("landing.the-stack.h1")}</h1>
      <Specializing></Specializing>
      <p>{t("landing.the-stack.p")}</p>
      <ScrollLink
        href={`#${props.nextElement}`}
        to={`${props.nextElement}`}
        smooth={true}
        duration={1200}
        aria-label={`Scroll to ${props.nextElement.replace(new RegExp('-', 'g'), " ")}.`}
        className={`scroll-${props.nextDirection}-button`}>
        <Icon className="scroll-down-button-icon" name={`arrow-thick-${props.nextDirection}`}></Icon>
      </ScrollLink>
    </section>
  );
};

