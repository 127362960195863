// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js!../../../theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Label {
  font-weight: 500;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--text-color-mix);
}
.Label:hover {
  color: var(--text-color-mix);
}

`, "",{"version":3,"sources":["webpack://./src/client/HoosatUI/src/Elements/Text/Label/Label.css"],"names":[],"mappings":"AAEA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,eAAe;EACf,mBAAmB;EACnB,4BAA4B;AAC9B;AACA;EACE,4BAA4B;AAC9B","sourcesContent":["@import '../../../theme';\n\n.Label {\n  font-weight: 500;\n  padding: 0.5rem 1rem;\n  font-size: 1rem;\n  line-height: 1.5rem;\n  color: var(--text-color-mix);\n}\n.Label:hover {\n  color: var(--text-color-mix);\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
